import airbrake from '../../../services/exception-notifier/client'
import { validTokenFormat, validCheckDigit } from './validate-token'

const validatePartnerNotificationToken = async ({ token }) => {
  if (!validTokenFormat(token)) {
    await airbrake.notify(`Partner notification token ${token} is in incorrect format`)

    return {
      context: {
        tokenStatus: 'token_invalid',
      },
    }
  }

  if (!validCheckDigit(token)) {
    await airbrake.notify(`Check digit is incorrect on Partner notification token ${token}`)
    return {
      context: { tokenStatus: 'token_invalid' },
    }
  }

  const data = JSON.stringify({
    partnerNotificationToken: token,
  })

  const result = await fetch('/api/validate-partner-notification-token', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: data,
  })

  const parsed = await result.json()

  if (result.status !== 200) return Promise.reject(parsed)

  const tokenData = parsed?.data?.partner_notification_token_validator

  return {
    context: {
      offerToken: token,
      tokenStatus: tokenData.result,
      infection_types: tokenData.infection_types,
    },
  }
}

export default validatePartnerNotificationToken
