import verifyLuhnModN from '../helpers/luhn-mod-n-verifier'

const TOKEN_FORMAT = /^.+-.{7}$/
const TOKEN_CHARACTER_SET = 'abcdefghjkmnpqrstuwxyz'

export const validTokenFormat = (token) => (
  TOKEN_FORMAT.test(token)
)

export const validCheckDigit = (token) => {
  const tokenWithoutGenderOrHypens = token.replace(/^.*-/g, '')

  return verifyLuhnModN(TOKEN_CHARACTER_SET, tokenWithoutGenderOrHypens)
}
